<template>
  <div>
    <h2 class="md-subtitle">or login with the following:</h2>
    <div class="social-auth centered-container">
      <md-content @click.prevent="onLogin('google')">
        <img src="../../assets/google-logo.png" alt="Fac3d.xyz Google Login">
      </md-content>
      <md-content @click.prevent="onLogin('twitter')">
        <img src="../../assets/twitter-logo.png" alt="Fac3d.xyz Twitter Login">
      </md-content>
    </div>
  </div>
</template>
<script type="text/babel">
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Fac3dSocialLogin',
  computed: {
    ...mapGetters({
      isUserLoggedIn: 'getIsUserLoggedIn',
    }),
  },
  methods: {
    ...mapActions(['trySocialLogin']),
    onLogin(args) {
      this.trySocialLogin(args)
        .then(() => {
          this.$router.push({ path: '/' });
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
  },
  watch: {
    isUserLoggedIn(val) {
      if (val) {
        this.$store.dispatch('onSuccessfulLogin');
        this.$router.push('/');
      }
    },
  },
};
</script>
<style scoped lang="scss">
.social-auth {
  display: flex;
  width: 100%;
  justify-content: space-around;
  img {
    max-width: 80px;
  }
  .md-content {
    background: transparent !important;
  }
}
</style>
