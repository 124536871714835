<template>
  <div class="centered-container">
    <md-content>

      <div class="title">
        <div class="md-title">Login to Fac3D</div>
        <!-- <div class="md-body-1">Build beautiful apps with Material Design and Vue.js</div> -->
      </div>

      <form class="form" autocomplete="new_user">
        <md-field>
          <label>Email</label>
          <md-input v-model="login.email" autofocus></md-input>
        </md-field>

        <md-field md-has-password>
          <label>Password</label>
          <md-input autcomplete="new_password" v-model="login.password" type="password"></md-input>
        </md-field>
      </form>

      <div class="actions md-layout md-alignment-center-space-between">
        <!-- <a href="/resetpassword">Reset password</a> -->
        <md-button class="md-raised" @click="onRegister">Don't have an account?</md-button>
        <md-button class="md-raised md-primary" @click="onSubmit">Login</md-button>
      </div>

      <div class="loading-overlay" v-if="loading">
        <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
      </div>

    </md-content>
    <!-- <div class="background" /> -->
  </div>
</template>
<script>
export default {
  name: 'FluffyRegisterComponent',
  props: ['isPaying'],
  data() {
    return {
      login: {
        email: '',
        password: '',
      },
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      try {
        const res = await this.$store.dispatch('tryUserLogin', this.login);
        this.loading = false;
        if (res && !this.isPaying) await this.$router.push({ path: '/' });
      } catch {
        this.loading = false;
        this.login.email = '';
        this.login.password = '';
      }
    },
    onRegister() {
      if (!this.isPaying) this.$router.push({ name: 'Register' });
      else this.$parent.$emit('goRegister');
    },
  },
};
</script>
<style scoped>
.md-content.md-theme-default {
  background: transparent !important;
}
</style>
