<template>
  <div class="centered-container">
    <md-content>

      <div class="title">
        <div class="md-title">Sign Up for Fac3D</div>
        <!-- <div class="md-body-1">Build beautiful apps with Material Design and Vue.js</div> -->
      </div>

      <div class="form">
        <md-field>
          <label>Name</label>
          <md-input v-model="form.name" autofocus></md-input>
        </md-field>

        <md-field>
          <label>Email</label>
          <md-input v-model="form.email" type="email" autofocus></md-input>
        </md-field>

        <md-field md-has-password>
          <label>Password</label>
          <md-input v-model="form.password" type="password"></md-input>
        </md-field>
      </div>

      <div class="actions md-layout md-alignment-center-space-between">
        <!-- <a href="/resetpassword">Reset password</a> -->
        <md-button class="md-raised" @click="onLogin">Have an account?</md-button>
        <md-button class="md-raised md-primary" @click="onSubmit">Sign Up</md-button>
      </div>

      <div class="loading-overlay" v-if="loading">
        <md-progress-spinner md-mode="indeterminate" :md-stroke="2"></md-progress-spinner>
      </div>

    </md-content>
    <!-- <div class="background" /> -->
  </div>
</template>
<script>
export default {
  name: 'FluffyRegisterComponent',
  props: ['isPaying'],
  computed: {
    showLogin() {
      return this.$route.name !== 'SupportView';
    },
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      try {
        await this.$store.dispatch('registerUser', this.form);
        this.loading = false;
        if (this.isPaying === false || this.$route.path.includes('support')) await this.$router.push({ path: '/' });
      } catch {
        this.loading = false;
      }
    },
    onLogin() {
      if (!this.isPaying) this.$router.push({ name: 'Login' });
      else this.$parent.$emit('goLog');
    },
  },
};
</script>
<style scoped>
.md-content.md-theme-default {
  background: transparent !important;
}
</style>
