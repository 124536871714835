<template>
  <section class="auth-wrapper">
    <!-- <md-button v-if="!isPaying" @click="$router.push({path: '/'})">
      &lsaquo; back
    </md-button> -->
    <Register
      :isPaying="isPaying"
      v-if="registering || $route.name === 'Register'"
    />
    <Login
      :isPaying="isPaying"
      v-else-if="logging || $route.name === 'Login'"
    />
    <SocialLogins></SocialLogins>
  </section>
</template>

<script>
import SocialLogins from '@/components/auth/SocialLogins.vue';
import Register from '../components/auth/Register.vue';
import Login from '../components/auth/Login.vue';

export default {
  name: 'AuthenticationView',
  computed: {
    isPaying() {
      return this.$route.path.includes('support');
    },
  },
  components: {
    Register,
    Login,
    SocialLogins,
  },
  data() {
    return {
      loading: false,
      registering: false,
      logging: false,
    };
  },
  methods: {
    goRegister() {
      this.registering = true;
      this.logging = false;
    },
    goLogin() {
      this.logging = true;
      this.registering = false;
    },
  },
  mounted() {
    if (this.isPaying) this.registering = true;
    this.$on('goLog', () => {
      this.goLogin();
    });
    this.$on('goRegister', () => {
      this.goRegister();
    });
  },
};
</script>
